<ion-content>


  <div class="gradient-background"></div>
  <div class="title-container">
    <img src="../../assets/cursosimages/UNIPROTEC-Logotipo_color.png" alt="Title Image" class="title-image">
  </div>

  <ion-toolbar class="filters-toolbar">
    <ion-row class="searchbar-row" align-items-center>
      <ion-col>
        <ion-searchbar class="items-searchbar" animated [(ngModel)]="searchQuery" (ionChange)="searchList()"
          placeholder="Filter by name..."></ion-searchbar>
      </ion-col>

    </ion-row>


  </ion-toolbar>

  <swiper [config]="swiperConfig">

    <ng-template swiperSlide *ngFor="let article of articlelist">

      <ion-row class="slide-inner-row">
        <ion-col>
          <img src="https://via.placeholder.com/300x150" alt="Image 2">
          <h2>{{ article.nombre }}</h2>
          <h3>{{ article.subtitulo }}</h3>
          <p>{{ article.contenido }}</p>
        </ion-col>
      </ion-row>
    </ng-template>
  </swiper>




  <swiper [config]="swiperConfig">

    <ng-template swiperSlide *ngFor="let article of articlelist">

      <ion-row class="slide-inner-row">
        <ion-col>

          <ion-row class="image-row">
            <ion-col size="12">
              <a class="image-anchor" (click)="navigateToVideos(item.slug)">
                <app-image-shell [display]="'cover'" animation="spinner" class="item-picture" [src]="article?.picture">
                  <app-aspect-ratio [ratio]="{w:365, h:125}">
                  </app-aspect-ratio>
                </app-image-shell>
              </a>
            </ion-col>
          </ion-row>
          <h2>{{ article.nombre }}</h2>
          <h3>{{ article.subtitulo }}</h3>
          <p>{{ article.contenido }}</p>
        </ion-col>
      </ion-row>
    </ng-template>
  </swiper>







  <!--
  <swiper-container *ngIf="articlelist && articlelist.length > 0">

    <swiper-slide swiperSlide *ngFor="let article of articlelist">

      <ion-row class="slide-inner-row">
        <ion-col>
          <img src="https://via.placeholder.com/300x150" alt="Image 2">
          <h2>{{ article.nombre }}</h2>
          <h3>{{ article.subtitulo }}</h3>
          <p>{{ article.contenido }}</p>
        </ion-col>
      </ion-row>

    </swiper-slide>
  </swiper-container>
-->





  <div class="info-wrapper">
    <h3 class="info-title">aRTICLESs</h3>
    <p class="info-paragraph">
      Noticias
    </p>
  </div>

  <swiper *ngIf="articlelist.length > 0" [config]="swiperConfig" class="walkthrough-slides"
    (swiper)="setSwiperInstance($event)" (init)="swiperInit()" (slideChangeTransitionStart)="slideWillChange()">
    <div class="swiper-wrapper">
      <ng-template *ngFor="let article of articlelist">

        <p>debug2</p>
        <ion-row class="slide-inner-row first-slide illustration-and-decoration-slide">
          <ion-col class="illustration-col">
            <app-aspect-ratio [ratio]="{w:300, h:150}">
              <app-image-shell class="illustration-image" animation="spinner"
                [src]="'./assets/sample-images/walkthrough/walkthrough-illustration-1.svg'"
                [alt]="'walkthrough'"></app-image-shell>
            </app-aspect-ratio>

          </ion-col>

        </ion-row>



        <ion-row class="slide-inner-row">
          <ion-col>
            <img src="https://via.placeholder.com/300x150" alt="Image 2">
            <h2>{{ article.nombre }}</h2>
            <h3>{{ article.subtitulo }}</h3>
            <p>{{ article.contenido }}</p>
          </ion-col>
        </ion-row>
      </ng-template>
    </div>
    <div class="swiper-pagination"></div>
  </swiper>


  <div class="info-wrapper">
    <h3 class="info-title">Cursos</h3>
    <p class="info-paragraph">
      Noticias
    </p>
  </div>

  <swiper [config]="swiperConfig" class="walkthrough-slides" (swiper)="setSwiperInstance($event)" (init)="swiperInit()"
    (slideChangeTransitionStart)="slideWillChange()">


    <ng-template swiperSlide *ngFor="let article of articlelist">
      <div class="swiper-slide" *ngFor="let curso of cursoslist">
        <ion-row class="slide-inner-row">
          <ion-col>
            <img [src]="curso.picture" alt="{{ curso.nombre }}" />
            <h2>{{ curso.nombre }}</h2>
            <p>{{ curso.descripcion }}</p>
          </ion-col>
        </ion-row>
      </div>
    </ng-template>

  </swiper>




</ion-content>