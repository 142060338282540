<ion-app dir="{{textDir}}">
  <!-- when=false means that the split pane will never expand automatically -->
  <!-- For more info check https://ionicframework.com/docs/api/split-pane -->
  <ion-split-pane contentId="menu-content" when="false">
    <ion-menu contentId="menu-content" class="app-sidemenu">
      <ion-header>
        <ion-toolbar>
          <ion-row class="user-details-wrapper">
            <ion-col size="3">
              <app-aspect-ratio [ratio]="{w: 1, h: 1}">
                <app-image-shell class="user-avatar" animation="spinner"
                  [src]="'./assets/uniprotec/DancingApps-removebg.png'"></app-image-shell>
              </app-aspect-ratio>
            </ion-col>
            <ion-col class="user-info-wrapper" size="8">
              <h3 class="user-name">Uniprotec</h3>

            </ion-col>
          </ion-row>

        </ion-toolbar>
      </ion-header>
      <ion-content>
       

        <ion-list>
          <ion-list-header>
            <ion-label>Navegacion</ion-label>
          </ion-list-header>
          <ion-menu-toggle autoHide="true">
            <ion-item [routerLink]="['/app/cursos']">
              <ion-icon slot="start" name="home-outline"></ion-icon>
              <ion-label>
                Novedades
              </ion-label>
            </ion-item>
            <ion-item [routerLink]="['/app/cursos']">
              <ion-icon slot="start" name="medal-outline"></ion-icon>
              <ion-label>
                Cursos
              </ion-label>
            </ion-item>
            <ion-item [routerLink]="['/app/cursos/videos']">
              <ion-icon slot="start" name="reader-outline"></ion-icon>
              <ion-label>
                Documentacion
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list><!-- -->
      </ion-content>
    </ion-menu>
    <!-- Main app content gets rendered in this router-outlet -->
    <ion-router-outlet id="menu-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>